.mention {
    border: 1px solid #000;
    border-radius: 0.4rem;
    box-decoration-break: clone;
    padding: 0.1rem 0.3rem;
}


.richEditorCustom {
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    padding: 0px 11px;
    border-radius: 6px;
    margin-bottom: 20px;
}


.richEditorCustom:active {
    border-color: #1677ff;
    border-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    outline: 0px;
}
